export enum AppScript {
    dgUI = 'dg-ui',
    /** @deprecated */
    wsApp = 'ws-app',
    /** @deprecated */
    dgComponentMain = 'dg-component-main',
    /** @deprecated */
    dgSpasMain = 'dg-spas-main',
    /** @deprecated */
    dgApp = 'dg-app',
}
