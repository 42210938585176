/* eslint-disable no-console */
/**
 * Wrap Loader Script in a Immediately Invoked Function Expression to avoid leaking our code into the global namespace
 * This is the script that kicks off everything else
 */
import { loadDg } from './service';
import logger from './getLogger';
import { initializeDGDataHub as initializeDdcDGDataHub } from './ddcService';

(async function () {
    if (window.DDC) {
        try {
            await initializeDdcDGDataHub();
        } catch (error) {
            console.error('there was an error initializing dataHub with DDC API', error);
        }
    }

    try {
        await loadDg();
        logger.debug('DG loader finished successfully');
    } catch (error) {
        logger.error('DG loader error', error);
    }
})();
