import { Brands } from '@/enums/brands';
import { Environments } from '@/enums/environments';
import { ReadonlyDGDataHub } from '@/models/DGDataHub.readonly.model';

function getCdnUrl(): string {
    const env = ReadonlyDGDataHub.environment;
    const cdnHost = env === Environments.prod ? 'cdn.dg.toyota.com' : `cdn.${env}.dg.toyota.com`;
    return `https://${cdnHost}`;
}

export function getDgUiScriptUrl(version: string): URL[] {
    const env = ReadonlyDGDataHub.environment;
    const brand = ReadonlyDGDataHub.brand;
    const dgUiScript = brand === Brands.lexus ? __dguiHashEntryLexus__ : __dguiHashEntryToyota__;

    if (env === Environments.local) {
        const port = brand === Brands.lexus ? '8081' : '8080';
        return [new URL(dgUiScript, `http://localhost:${port}`)];
    }

    const cdnUrl = getCdnUrl();
    return [
        new URL(`/deploy/dg-ui/${version}/${brand}/${dgUiScript}`, cdnUrl),
        new URL(`/deploy/dg-ui/${version}/${brand}/dg-ui-entry.min.js`, cdnUrl),
    ];
}

export function isDgUiScriptRequested(): boolean {
    return !!findMatchingScriptOnDocument((script) => script.src?.includes('dg-ui-entry.'));
}
export function isDgAuthScriptRequested(): boolean {
    return !!findMatchingScriptOnDocument((script) => script.src?.endsWith('dg-auth.min.js'));
}
export function getRequestedDgComponentsScript(): HTMLScriptElement | undefined {
    return findMatchingScriptOnDocument((script) => script.src?.includes('dg-component-main'));
}

function findMatchingScriptOnDocument(test: (script: HTMLScriptElement) => boolean) {
    for (const script of document.scripts) {
        if (test(script)) {
            return script;
        }
    }
    return undefined;
}
