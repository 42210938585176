import logger from '../getLogger';
export const buildYourLexusIframeId = 'lexus-iframe-id';
export const buildYourLexusUrl = '//lexus.com/build-your-lexus';

export const getLexusBuildsIFrame = (): HTMLIFrameElement | null => {
    const selectors = [
        () => document.getElementById(buildYourLexusIframeId),
        () => document.getElementById('resizeIframe'),
        () => document.querySelector(`iframe[src*="${buildYourLexusUrl}"]`),
    ];
    try {
        for (const selector of selectors) {
            const element = selector();
            if (element && isIframeHtmlElement(element)) {
                return element;
            }
        }
    } catch (error) {
        logger.error('Error getting lexus builds iframe', error);
    }
    return null;
};

function isIframeHtmlElement(e: Element): e is HTMLIFrameElement {
    return e.tagName.toUpperCase() === 'IFRAME';
}
