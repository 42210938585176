import logger from '@/loader-library/getLogger';

export async function loadScript(urls: URL[]): Promise<void> {
    for (const url of urls) {
        const src = url.href;
        try {
            logger.debug('Loading script', src);
            await _loadScript(src);
            return;
        } catch (error) {
            logger.warn('Unable to load script', src, error);
        }
    }
    throw new Error('Unable to load scripts');
}

function _loadScript(src: string): Promise<Event> {
    return new Promise<Event>((resolve, reject) => {
        const appScript = window.document.createElement('script');
        appScript.onload = (ev) => resolve(ev);
        appScript.onerror = (ev) => reject(ev);
        appScript.setAttribute('type', 'text/javascript');
        appScript.setAttribute('src', src);
        window.document.body.appendChild(appScript);
    });
}
