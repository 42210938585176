import { Environments } from '@/enums/environments';
import BaseLogger from './baseLogger.model';
import { ReadonlyDGDataHub } from './DGDataHub.readonly.model';

export default class Logger<T extends string> extends BaseLogger {
    constructor(moduleName: T extends '' ? never : T) {
        const env = process.env.NODE_ENV === 'test' ? Environments.demo : ReadonlyDGDataHub.environment;
        const context = __DEV__ ? { isInIFrame: ReadonlyDGDataHub.isInIFrame } : undefined;
        super('dg-ui', env, moduleName, context);
    }
}
